import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "bootstrap";
import VueSocialSharing from "vue-social-sharing";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import PortalVue from "portal-vue";
import './index.css'
// import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);
Vue.use(PortalVue);
Vue.use(VueToast, {
  position: "top-right"
});

// Vue.use(VueGtag, {
//   config: { id: "G-0YQE7LWMWJ" }
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
