import client from "./requestclient";

const realtor = {
  fetchActivationData: async () => {
    return await client({ URL_PATH: "getactivationdata", method: "GET" });
  },

  activateRealtor: async () => {
    return await client({
      URL_PATH: "activaterealtorforbonus",
      method: "POST"
    });
  },
  withdrawActivationBonus: async () => {
    return await client({
      URL_PATH: "withdrawactivationbonus",
      method: "POST"
    });
  }
};

export default realtor;
