<template>
  <div id="app">
    <portal-target name="modal"></portal-target>

    <router-view @open-leaderboard="openLeaderboard"></router-view>
  </div>
</template>

<script>
import realtor from "./services/realtor";
export default {
  name: "App",
  data() {
    return {
      showLeaderboard: true
    };
  },
  provide() {
    return {
      showLeaderboard: this.showLeaderboard,
      openLeaderboard: () => {
        this.showLeaderboard = false;
      }
    };
  },
  computed: {},
  methods: {
    openLeaderboard() {
      this.showLeaderboard = true;
    }
  },

  mounted() {
    realtor
      .fetchActivationData()
      .then(data => {
        if (data.activateddata != null) {
          this.$store.dispatch(
            "saveActivatedStatus",
            data.activateddata.status
          );
        } else {
          this.$store.dispatch("saveActivatedStatus", null);
          this.$store.dispatch("setActivation", null);
        }
      })
      .catch(err => {
        console.log(err);

        this.$toast.error("Please check your network and refresh page");
      });
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
/*@font-face {*/
/*  font-family: "Gotham-Thin";*/
/*  src: url("./assets/Gotham-Font/Gotham-Thin.otf");*/
/*}*/
/*@font-face {*/
/*  font-family: "Gotham-Light";*/
/*  src: url("./assets/Gotham-Font/GothamLight.ttf");*/
/*}*/
@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/shelta_fonts/GothamMedium.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Book";
  src: url("./assets/shelta_fonts/GothamBook.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("./assets/shelta_fonts/GothamBold.otf") format("opentype");
}
/*@font-face {*/
/*  font-family: "Gotham-Black";*/
/*  src: url("./assets/Gotham-Font/Gotham-Black.otf");*/
/*}*/
:root {
  --primary-color: #0033ea;
  --clr-red: rgb(234, 0, 0);
  --primary-rgb: 0, 51, 234;
  --primary-light-rgb: 198, 233, 250;
  --green-color: #2fc643;
  --deep-green: #38a146;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
body * {
  padding: 0;
  /* margin: 0; */
  box-sizing: border-box;
}
body *:focus {
  outline: 1px dotted #000;
}
#app {
  font-family: "Gotham-Book", sans-serif;
}
button {
  cursor: pointer;
  border: 0;
  transition: all 0.25s;
}
button:disabled {
  cursor: not-allowed !important;
}
.center-text {
  text-align: center;
}
a {
  display: inline-block;
  text-decoration: none !important;
}
p {
  margin: 0 !important;
}

.flex {
  display: flex;
  align-items: center;
}

.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

.flex-1 {
  flex: 1;
}

.gap-2 {
  gap: 2rem;
}

.gap-1 {
  gap: 1rem;
}
a {
  color: inherit;
}

label {
  display: block !important;
}
</style>
